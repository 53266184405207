<template>
  <div>
    <div>
      <div class="ContentHeader">
        <div class="HeaderAll">
          <slot name="titlebreadcrumb"></slot>
          <a-breadcrumb>
            <a-breadcrumb-item
              ><router-link :to="{ name: 'Home' }">Home</router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item v-for="(item, i) in breadcrumb" :key="i">
              <router-link
                :to="{ name: item.nameroute }"
                v-if="i + 1 < breadcrumb.length"
                >{{ item.name }}</router-link
              >
              <span v-else>{{ item.name }}</span>
            </a-breadcrumb-item>
          </a-breadcrumb>
        </div>

        <div class="ContentHeaderSearch">
          <a-input-search
            placeholder="Search Here"
            style="width: 327px"
            @search="onSearch"
            allowClear
            :default-value="
              $route.query.search != undefined ? $route.query.search : ''
            "
          />

          <a-button type="advancedsearch" ref="buttonadvanced" @click="advanced"
            ><a-badge :dot="dotAdvancedSearch"
              >Advanced Search <a-icon type="filter"
            /></a-badge>
          </a-button>
          <div
            class="advancedsearch-dropdown"
            :style="advancedsearch ? 'display: block;' : 'display: none;'"
            @click="closeadvance"
          >
            <div class="_dropdownwraper" :style="{ top: top + 'px' }">
              <div class="button-right-top">
                <a-button type="link" @click="advanced">
                  <span class="ant-modal-close-x"> <a-icon type="close" /></span
                ></a-button>
              </div>
              <a-form :form="form" @submit="Submit">
                <a-row :gutter="[15, 15]">
                  <a-col :lg="6"
                    ><a-form-item label="Nama Group">
                      <a-input
                        autocomplete="off"
                        v-decorator="[
                          'group',
                          {
                            initialValue:
                              $route.query.group != undefined
                                ? $route.query.group
                                : null,
                          },
                        ]"
                        placeholder="Input disini"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col :lg="6">
                    <a-form-item label="Nama Perusahaan">
                      <a-input
                        autocomplete="off"
                        v-decorator="[
                          'perusahaan',
                          {
                            initialValue:
                              $route.query.perusahaan != undefined
                                ? $route.query.perusahaan
                                : null,
                          },
                        ]"
                        placeholder="Input disini"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col :lg="6">
                    <a-form-item label="Link">
                      <a-input
                        autocomplete="off"
                        v-decorator="[
                          'link',
                          {
                            initialValue:
                              $route.query.link != undefined
                                ? $route.query.link
                                : null,
                          },
                        ]"
                        placeholder="Input disini"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :lg="24">
                    <div class="button-right">
                      <a-button type="default" @click="Reset">Reset</a-button>
                      <a-button type="primary" html-type="submit"
                        >Cari</a-button
                      >
                    </div>
                  </a-col>
                </a-row>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-table
      :rowKey="(record) => record.id"
      :columns="columns"
      :dataSource="data"
      :pagination="false"
      :loading="loading"
      :scroll="{ x: 1200, y: heighttable }"
      @change="handleTableChange"
    >
      <div slot="filterDropdown" slot-scope="{ column }">
        <div class="wrapbuttonsort">
          <a-button
            type="link"
            @click="
              handlesTbl('periode_awal_format', [
                undefined,
                'ascend',
                'descend',
              ])
            "
          >
            <div class="customsortdown">
              <div
                :style="
                  column.choseSort == 'periode_awal_format'
                    ? 'color:#0060D1'
                    : 'color:#77849D'
                "
              >
                Periode Awal
              </div>
              <!-- <div class="iconupdown">
                <a-icon
                  type="caret-up"
                  :style="{
                    color:
                      column.customSort == 'ascend' &&
                      column.choseSort == 'periode_awal_format'
                        ? '#0060D1'
                        : '#77849D',
                  }"
                />

                <a-icon
                  type="caret-down"
                  :style="{
                    color:
                      column.customSort == 'descend' &&
                      column.choseSort == 'periode_awal_format'
                        ? '#0060D1'
                        : '#77849D',
                  }"
                />
              </div> -->
            </div>
          </a-button>
        </div>
        <div>
          <a-button
            type="link"
            @click="
              handlesTbl('periode_akhir_format', [
                undefined,
                'ascend',
                'descend',
              ])
            "
          >
            <div class="customsortdown">
              <div
                :style="
                  column.choseSort == 'periode_akhir_format'
                    ? 'color:#0060D1'
                    : 'color:#77849D'
                "
              >
                Periode Akhir
              </div>
              <!-- <div class="iconupdown">
                <a-icon
                  type="caret-up"
                  :style="{
                    color:
                      column.customSort == 'ascend' &&
                      column.choseSort == 'periode_akhir_format'
                        ? '#0060D1'
                        : '#77849D',
                  }"
                />

                <a-icon
                  type="caret-down"
                  :style="{
                    color:
                      column.customSort == 'descend' &&
                      column.choseSort == 'periode_akhir_format'
                        ? '#0060D1'
                        : '#77849D',
                  }"
                />
              </div> -->
            </div>
          </a-button>
        </div>
      </div>

      <div slot="filterIcon" slot-scope="filtered, column" class="customSorter">
        <a-icon
          type="caret-up"
          :style="{
            color: column.customSort == 'ascend' ? '#0060D1' : '#77849D',
          }"
        />

        <a-icon
          type="caret-down"
          :style="{
            color: column.customSort == 'descend' ? '#0060D1' : '#77849D',
          }"
        />
      </div>

      <template slot="nama_group" slot-scope="text, record">
        <router-link :to="{ name: 'ListDaftarPT', params: { id: record.id } }">
          {{ text }} <a-icon type="right" />
        </router-link>
      </template>
      <template slot="periode" slot-scope="text, record">
        <div class="iconcalendar" style="padding-bottom: 10px">
          <img :src="require('@/assets/img/icon/periode_awal.svg')" />
          {{ record.periode_awal }}
        </div>
        <div class="iconcalendar">
          <img :src="require('@/assets/img/icon/periode_akhir.svg')" />
          {{ record.periode_akhir }}
        </div>
      </template>
      <template slot="jenis_asuransi" slot-scope="text">
        <div class="iconhealth" v-if="text == 'Health'">
          <img :src="require('@/assets/img/icon/health.svg')" />
          {{ text }}
        </div>

        <div class="iconhealth iconlife" v-if="text == 'Life'">
          <img :src="require('@/assets/img/icon/life.svg')" />{{
            text.toUpperCase()
          }}
        </div>

        <div class="iconhealth iconih" v-if="text == 'Ih'">
          <img :src="require('@/assets/img/icon/ih.svg')" />{{
            text.toUpperCase()
          }}
        </div>
      </template>

      <template slot="total_peserta" slot-scope="text">
        <div class="iconpeserta">
          <img :src="require('@/assets/img/icon/peserta.svg')" /> {{ text }}
        </div>
      </template>
      <template slot="action" slot-scope="text">
        <a
          v-if="text.startsWith('http') || text.startsWith('https')"
          :href="text"
          target="_blank"
        >
          <a-button type="download"
            >View <img :src="require('@/assets/img/icon/eye.svg')"
          /></a-button>
        </a>

        <a v-else :href="`//${text}`" target="_blank">
          <a-button type="download"
            >View <img :src="require('@/assets/img/icon/eye.svg')"
          /></a-button>
        </a>
      </template>
      <template slot="footer">
        {{ null }}
      </template>
    </a-table>
    <!-- pagination -->
    <div class="paginationcustom" style="margin-top: 0px !important">
      <a-pagination
        showSizeChanger
        @showSizeChange="onShowSizeChange"
        :pageSizeOptions="pageSizeOption"
        :pageSize.sync="pageSize"
        :total="total"
        v-model="page"
        :showTotal="
          (total, range) =>
            ` ${range[0]}-${range[1]}  of ${
              pagination.total == undefined ? '0' : pagination.total
            } `
        "
        @change="handlePageChange"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span v-if="props.value !== '1000000'">
            {{ props.value }} / page
          </span>
          <span v-if="props.value == '1000000'">All</span>
        </template>
      </a-pagination>
    </div>
    <!-- end pagination -->
  </div>
</template>

<script>
const columns = [
  {
    title: "Nama Group",
    dataIndex: "group",
    scopedSlots: { customRender: "nama_group" },
    sorter: true,
  },
  {
    title: "Nama Perusahaan",
    dataIndex: "nama_perusahaan",

    sorter: true,
  },

  {
    title: "Action",
    dataIndex: "link_power_bi",
    scopedSlots: { customRender: "action" },
    width: 200,
    align: "center",
  },
];
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "FormGroup" });
  },
  data() {
    return {
      columns,
      pageSizeOption: ["15", "30", "60", "1000000"],
      pagination: {},
      total: 20,
      page: 1,
      polisSorter: "",
      current: 1,
      pageSize: 15,
      loading: false,
      data: [],
      advancedsearch: false,
      click: 0,
      labels: "",
      heighttable: 0,
      top: 0,
      breadcrumb: [
        {
          name: "Report",
          nameroute: "",
          param: {},
        },
      ],
      paramssearch:
        this.$route.query.page != undefined &&
        this.$route.query.pageSizes != undefined
          ? Object.keys(this.$route.query).reduce((obj, key) => {
              if (key !== "page" && key !== "pageSizes") {
                obj[key] = this.$route.query[key];
              }

              return obj;
            }, {})
          : {},
      search:
        this.$route.query.search != undefined ? this.$route.query.search : "",
      dotAdvancedSearch:
        Object.keys(this.$route.query).length >= 15 &&
        this.$route.query.search == undefined
          ? true
          : Object.keys(this.$route.query).length >= 15 &&
            this.$route.query.search != ""
          ? true
          : false,
    };
  },
  computed: {
    group() {
      return this.$store.state.Auth.data.group;
    },
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    const search = this.search != "" ? { search: this.search } : {};
    const params = this.paramssearch != undefined ? this.paramssearch : {};

    this.fetch({
      page: this.page,
      pageSizes: this.pageSize,
      ...search,
      ...params,
    });
  },

  methods: {
    OpenLink(url) {
      let newUrl = window.decodeURIComponent(url);
      newUrl = newUrl.trim().replace(/\s/g, "");
      let newUrls = "";
      if (/^(:\/\/)/.test(newUrl)) {
        newUrls = `http${newUrl}`;
      }
      if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        newUrls = `http://${newUrl}`;
      }
      console.log(newUrls);
      return (window.location.href = newUrl);
    },
    handleResize() {
      if (screen.height <= 768) {
        this.heighttable = screen.height - 420;
      } else {
        this.heighttable = screen.height - 530;
      }
    },
    Reset() {
      this.paramssearch = {};
      this.dotAdvancedSearch = false;
      this.form.resetFields();
      this.advancedsearch = false;
      this.fetch({
        page: this.page,
        pageSizes: this.pageSize,
      });
      this.handlePageChange(this.page);
    },
    Submit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          //this.advancedsearch = false;
          this.fetch({
            page: 1,
            pageSizes: this.pageSize,
            ...values,
          });

          this.advancedsearch = false;
          this.paramssearch = values;
          this.dotAdvancedSearch = true;
        }

        const search = this.search != "" ? { search: this.search } : {};

        this.$router.push({
          query: {
            page: 1,
            pageSizes: this.pageSize,
            ...search,
            ...this.paramssearch,
          },
        });
      });
    },
    onShowSizeChange(current, pageSize) {
      const params = this.paramssearch;
      this.page = current;
      this.pageSize = pageSize;
      this.fetch({
        page: this.page,
        pageSizes: this.pageSize,
        search: this.search,
        ...params,
      });
      this.handlePageChange(current);
    },
    handlePageChange(page) {
      //checklist reset

      const pager = { ...this.pagination };
      pager.current = page;

      const search = this.search != "" ? { search: this.search } : {};
      const params = this.paramssearch != undefined ? this.paramssearch : {};

      if (params.search != undefined) {
        delete params.search;
      }

      this.fetch({
        page: pager.current,
        pageSizes: pager.pageSize,
        ...search,
        ...params,
      });

      this.$router.push({
        query: {
          page: pager.current,
          pageSizes: pager.pageSize,
          ...search,
          ...params,
        },
      });
    },
    handlesTbl(item, sortby) {
      if (this.labels == "") {
        this.labels = item;
      }

      if (this.labels != item) {
        this.labels = item;
        this.click = 0;
      }

      console.log("labels", this.labels);
      this.click += 1;

      if (this.click == 3) {
        this.click = 0;
      }

      let sorting = {
        field: item,
        order: sortby[this.click],
      };
      this.handleTableChange(this.page, "", sorting);
      this.columns[1].customSort = sortby[this.click];

      //set columnya ke sorter
      this.columns[1].choseSort = item;

      //console.log(this.columns[1].choseSort);
    },
    handleTableChange(page, filters, sorter) {
      if (sorter.field != "periode_awal" || sorter.field != "periode_akhir") {
        this.columns[1].customSort = "";
      }

      const pager = { ...this.pagination };
      const params = this.paramssearch;
      pager.current = page;

      if (params.search != undefined) {
        delete params.search;
      }

      this.fetch({
        page: pager.current,
        pageSizes: pager.pageSize,
        search: this.search,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...params,
      });

      this.$router.push({
        page: pager.current,
        pageSizes: pager.pageSize,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...params,
      });
    },
    advanced(e) {
      //console.log(this.$refs.buttonadvanced.$el.offsetTop);

      this.advancedsearch = !this.advancedsearch;
      this.top = this.$refs.buttonadvanced.$el.offsetTop + 45;
    },

    closeadvance(e) {
      if (e.target._prevClass == "advancedsearch-dropdown") {
        this.advancedsearch = false;
      }
    },

    fetch(params = {}) {
      // console.log(params);
      this.loading = true;
      this.$store
        .dispatch("getReport", {
          ...params,
        })
        .then((response) => {
          this.loading = false;
          const pagination = { ...this.pagination };
          pagination.total = response.data.total;
          pagination.pageSize = response.data.pageSize;
          pagination.page = response.data.page;
          this.total = pagination.total;
          this.data = response.data.data;
          this.pagination = pagination;
          this.model = [];
        });
    },

    isDeselect(e) {
      this.checkeds = false;
      if (this.model == []) {
        this.enableds = true;
      } else {
        if (this.model.filter((keys) => keys == true).length == 0) {
          this.enableds = true;
        } else {
          this.enableds = false;
        }
      }
    },
    isCheckAll(e) {
      Object.assign(this, {
        model: e.target.checked ? this.data.map((i) => true) : [],
      });

      this.enableds = e.target.checked ? false : true;
    },

    onSearch(value) {
      this.search = value;

      const search = this.search != "" ? { search: this.search } : {};
      this.fetch({
        page: 1,
        pageSizes: this.pageSize,
        ...search,
      });

      this.$router.push({
        query: {
          page: 1,
          pageSizes: this.pageSize,
          ...search,
        },
      });
    },
  },
};
</script>
